import React, { useEffect, useState } from 'react';
import ScrollIntoView from 'react-scroll-into-view';
import classes from '../styles/components/header.module.scss';

function Header() {
    var navState = false;
    const [offset, setOffset] = useState(0);
    const [sticky, setSticky] = useState(false);

    const toggleNav = () => {
        navState = !navState;
        if (navState) {
            document.getElementById("nav").style.display="flex";
        }
        else {
            document.getElementById("nav").style.display="none";
        }
    }

    function StickyScroll() {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        if (offset !== 0) {
            setSticky(true)
        }
        else {
            setSticky(false)
        }
        return () => window.removeEventListener('scroll', onScroll);
    }

    useEffect(() => {
        StickyScroll()
    });

    //console.log(offset);
    return (
        <header className={ sticky ? `${classes.header} ${classes.sticky}` : classes.header}>
            <h1 className={classes.header__title} onClick={toggleNav}>Dibistan</h1>
            <div id="nav" className={classes.header__navigation}>
                <ul className={classes.header__navigation__navbar}>
                    <ScrollIntoView selector={"#slide1"} scrollOptions={{block: "center"}}><li className={classes.header__navigation__navbar__link}>Accueil</li></ScrollIntoView>
                    <ScrollIntoView selector={"#slide2"} scrollOptions={{block: "center"}}><li className={classes.header__navigation__navbar__link}>Informations</li></ScrollIntoView>
                    <ScrollIntoView selector={"#slide3_websites"} scrollOptions={{block: "center"}}><li className={classes.header__navigation__navbar__link}>Sites web</li></ScrollIntoView>
                    <ScrollIntoView selector={"#slide3_discords"} scrollOptions={{block: "center"}}><li className={classes.header__navigation__navbar__link}>Discords</li></ScrollIntoView>
                </ul>
            </div>
        </header>
    );
}

export default Header;