import React from 'react';
import classes from '../styles/slides/slide1.module.scss'

function Slide1() {
    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const flagx = getRandomInt(-25, 25);
    const flagy = getRandomInt(-25, 25);

    const flag_style = {
        backgroundPositionX: flagx + "vw",
        backgroundPositionY: flagy + "vh"
    };

    return (
        <div id="slide1">
            <div className={classes.slide1}>
                <div className={classes.slide1__bg} style={flag_style}/>
                <div className={classes.slide1__box}>
                    <h2 className={classes.slide1__box__title}>Helloo !</h2>
                    <p className={classes.slide1__box__text}>
                        Bienvenue sur le site principal du Dibistan. Ce site  regroupe toutes les informations liées au Dibistan. Vous y trouverez tous sites liés à la micro-nation ainsi que les serveurs discords principaux.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Slide1;