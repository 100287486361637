import React, { useState } from 'react';
import classes from '../styles/components/footer.module.scss';
import Modal from "react-modal";
import { CgClose } from 'react-icons/cg';


function Footer(props) {
    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
      }

    return (
      <div className={classes.footer}>
            <p className={classes.footer__text}>
                Ce site web est maintenu par l'équipe des Développeurs du Dibistan et
                plus en particulier par Delta Δ#7715. Vous pouvez nous contacter directement via Discord sur le serveur de la RPD,
                ou celui des <a className={classes.footer__text__link} href="https://discord.gg/k9xy6FD5bv">DBY Devs</a>.
            </p>

            <Modal
                className={classes.footer__modal}
                isOpen={isOpen}
                onRequestClose={toggleModal}
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0)'
                    },
                    content: {
                      position: 'absolute',
                      top: '200px',
                      left: '140px',
                      right: '140px',
                      bottom: '100px',
                      border: '1px solid #ccc',
                      background: '#e0e0e0',
                      overflow: 'auto',
                      WebkitOverflowScrolling: 'touch',
                      borderRadius: '15px',
                      outline: 'none',
                      padding: '20px'
                    }
                }}
            >
                <div className={classes.footer__modal__header}>
                    <h2 className={classes.footer__modal__header__title}>Les développeurs</h2>
                    <CgClose className={classes.footer__modal__header__close} onClick={toggleModal} />
                </div>
                <p className={classes.footer__modal__text}>
                    lipsum
                </p>
            </Modal>
      </div>
    );
}

export default Footer;

/*
<span className={classes.footer__text__link} onClick={toggleModal}>


<Modal
  ...
  style={{
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)'
    },
    content: {
      position: 'absolute',
      top: '40px',
      left: '40px',
      right: '40px',
      bottom: '40px',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '20px'
    }
  }}
  ...
></Modal>
*/