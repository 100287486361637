import React, { useEffect, useState } from 'react';
import classes from './styles/app.module.scss'

import Header from './components/Header';
import Slide1 from './components/Slide1';
import Slide2 from './components/Slide2';
import Slide3 from './components/Slide3';
import Footer from './components/Footer';

function App() {
    const [data, setData] = useState()
    
    useEffect(() => {
        // alert("Ce site est en développement et n'est pas terminé. C'est normal qu'il y ait des bugs et des informations partielles ou incorrectes");
        fetch('data.json').then(response => {
            response.json().then(data => {
                setData(data)
            })
        })
    }, [])


    return (
        <div className={classes.app}>
            <Header />
            <Slide1 />
            <Slide2 />
            { data ? <Slide3 data={data} /> : null }
            <Footer />
        </div>
    )
}

export default App;