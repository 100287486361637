import React from 'react';
import classes from '../styles/slides/slide3.module.scss';
import { CgClose } from 'react-icons/cg';

function Slide3(props) {
    var activeModal = null;

    const toggleModal = (name) => () => {
        var modal = document.getElementById("modal" + name);
        modal.style.display = modal.style.display === "flex" ? "none" : "flex";
        if (modal.style.display === "flex") {
            if (activeModal) {
                activeModal.style.display = "none";
            }
            activeModal = modal;
        } else {
            if (activeModal === modal) {
                activeModal = null;
            }
        }
    }

    return (
        <div>
            <div id="slide3_websites" className={classes.slide3}>
                <h1 className={classes.slide3__title}>{props.data.sites.title}</h1>
                <div className={classes.slide3__cards}>
                    {
                        props.data.sites.content.map((item, index) => {
                            return (
                                <div id={"card" + item.id} key={index} className={classes.slide3__cards__card} onClick={toggleModal(item.id)}>
                                    <h2>{item.name}</h2>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    props.data.sites.content.map((item, index) => {
                        return (
                            <div id={"modal" + item.id} key={index} className={classes.slide3__modals}>
                                <div className={classes.slide3__modals__header}>
                                    <h1 className={classes.slide3__modals__header__title}>{item.title}</h1>
                                    <CgClose className={classes.slide3__modals__header__close} onClick={toggleModal(item.id)} />
                                </div>
                                <a href={item.url} className={classes.slide3__modals__link}>Lien</a>
                                <p className={classes.slide3__modals__descr}>{item.descr}</p>
                            </div>
                        )
                    })
                }
            </div>

            <div id="slide3_discords" className={classes.slide3}>
                <h1 className={classes.slide3__title}>{props.data.discords.title}</h1>
                <div className={classes.slide3__cards}>
                    {
                        props.data.discords.content.map((item, index) => {
                            return (
                                <div id={"card" + item.id} key={index} className={classes.slide3__cards__card} onClick={toggleModal(item.id)}>
                                    <h2>{item.name}</h2>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    props.data.discords.content.map((item, index) => {
                        return (
                            <div id={"modal" + item.id} key={index} className={classes.slide3__modals}>
                                <div className={classes.slide3__modals__header}>
                                    <h1 className={classes.slide3__modals__header__title}>{item.name}</h1>
                                    <CgClose className={classes.slide3__modals__header__close} onClick={toggleModal(item.id)} />
                                </div>
                                <a href={item.url} className={classes.slide3__modals__link}>Lien</a>
                                <p className={classes.slide3__modals__descr}>{item.descr}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Slide3;

/*

toggleModal(index, item.name)
onClick={() => setTest("")}

datagroup in props.data) {
    dataGroup.content.map((item, index) => {
        return (
            <div id={"card" + item.name} key={index} className={classes.slide3__cards__card} onClick={toggleModal(item.name)}>
                <h2>{item.name}</h2>
            </div>
        )
    })
}

{
    props.data.content.map((item, index) => {
        return (
            <div id={"modal" + item.name} key={index} className={classes.slide3__modals}>
                <div className={classes.slide3__modals__header}>
                    <h1 className={classes.slide3__modals__header__title}>{item.name}</h1>
                    <CgClose className={classes.slide3__modals__header__close} onClick={toggleModal(item.name)} />
                </div>
                <a href={item.url} className={classes.slide3__modals__link}>Lien</a>
                <p className={classes.slide3__modals__descr}>{item.descr}</p>
            </div>
        )
    })
}





*/